import { Actor } from "skytree";
import vertexShaderGlsl from "./vertex.glsl";
import fragmentShaderGlsl from "./fragment.glsl";
import { EveryFrame, ScreenSize } from "@anderjason/web";

export interface StripesProps {
  parentElement: HTMLElement;
}

export class Stripes extends Actor<StripesProps> {
  onActivate() {
    const canvas = document.createElement('canvas');
    this.props.parentElement.appendChild(canvas);

    const gl = canvas.getContext('webgl');

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertexShader, vertexShaderGlsl);
    gl.compileShader(vertexShader);

    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentShaderGlsl);
    gl.compileShader(fragmentShader);

    const program = gl.createProgram() as any;
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);

    const vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

    const itemSize = 2;
    const numItems = vertices.length / itemSize;

    program.aVertexPosition = gl.getAttribLocation(program, 'aVertexPosition');
    gl.enableVertexAttribArray(program.aVertexPosition);
    gl.vertexAttribPointer(program.aVertexPosition, itemSize, gl.FLOAT, false, 0, 0);

    gl.useProgram(program);

    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);

    this.addActor(
      new EveryFrame({
        fn: () => {
          gl.viewport(0, 0, canvas.width, canvas.height);
          gl.clearColor(1, 1, 1, 1);
          gl.clear(gl.COLOR_BUFFER_BIT);
    
          program.uTime = gl.getUniformLocation(program, 'uTime');
          gl.uniform1f(program.uTime, 0.001 * performance.now());
    
          gl.drawArrays(gl.TRIANGLES, 0, numItems);
        }
      })
    )
    
    this.cancelOnDeactivate(
      ScreenSize.instance.availableSize.didChange.subscribe(availableSize => {
        canvas.width = availableSize.width;
        canvas.height = availableSize.height;
  
        program.uResolution = gl.getUniformLocation(program, 'uResolution');
        gl.uniform2fv(program.uResolution, [window.innerWidth, window.innerHeight]);        
      }, true)
    );
  }
}